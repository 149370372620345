@import url("https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css");
body{margin-top:20px;}

.steps .step {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    text-align: center
}

.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -1px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}

.steps .step .step-icon-wrap::before {
    left: 0
}

.steps .step .step-icon-wrap::after {
    right: 0
}

.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 38px;
    line-height: 81px;
    z-index: 5
}

.steps .step .step-title {
    margin-top: 16px;
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
    display: none
}

.steps .step:last-child .step-icon-wrap::after {
    display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    background-color: #0da9ef
}

.steps .step.completed .step-icon {
    border-color: #0da9ef;
    background-color: #0da9ef;
    color: #fff
}

@media (max-width: 576px) {
    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 768px) {
    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 991px) {
    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 1200px) {
    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

.bg-faded, .bg-secondary {
    background-color: #f5f5f5 !important;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-box {
  width: 500px;
  padding: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.gradient-text {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  -webkit-background-clip: text;
  color: transparent;
}

.form-box input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px; 
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 18px;
  transition: all 0.3s ease;
}

input:focus {
  outline: none;
  border-bottom: 2px solid #4CAF50;
}

input:not(:placeholder-shown) + label,
input:focus + label {
  font-size: 14px;
  color: #4CAF50;
  transform: translateY(-24px);
}

label {
  position: absolute;
  left: 10px;
  bottom: 18px;
  font-size: 18px;
  color: #ccc;
  pointer-events: none;
  transition: all 0.3s ease;
}

input:focus + label {
  color: #4CAF50;
}

.form-box button {
  background-color: #2196f3;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-box button:hover {
  background-color: #0e81d4;

}
.form-box button:hover {
  transform: translateY(2px);

}

.modal-90w{
width:90vw;
}

/* App.css */

body, html {

  
  background: linear-gradient(to bottom right, #74ebd5, #ACB6E5);
}

.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: rise 20s infinite;
}

.bubble:nth-child(1) {
  left: 10%;
  animation-duration: 15s;
  animation-delay: 2s;
  width: 60px;
  height: 60px;
}

.bubble:nth-child(2) {
  left: 20%;
  animation-duration: 12s;
  animation-delay: 4s;
  width: 20px;
  height: 20px;
}

.bubble:nth-child(3) {
  left: 25%;
  animation-duration: 20s;
  animation-delay: 0s;
  width: 50px;
  height: 50px;
}

.bubble:nth-child(4) {
  left: 40%;
  animation-duration: 18s;
  animation-delay: 3s;
  width: 70px;
  height: 70px;
}

.bubble:nth-child(5) {
  left: 50%;
  animation-duration: 25s;
  animation-delay: 1s;
  width: 30px;
  height: 30px;
}

.bubble:nth-child(6) {
  left: 60%;
  animation-duration: 17s;
  animation-delay: 2s;
  width: 40px;
  height: 40px;
}

.bubble:nth-child(7) {
  left: 70%;
  animation-duration: 14s;
  animation-delay: 5s;
  width: 60px;
  height: 60px;
}

.bubble:nth-child(8) {
  left: 80%;
  animation-duration: 20s;
  animation-delay: 4s;
  width: 50px;
  height: 50px;
}

.bubble:nth-child(9) {
  left: 90%;
  animation-duration: 18s;
  animation-delay: 3s;
  width: 40px;
  height: 40px;
}

@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translateX(30px);
  }
  100% {
    bottom: 100%;
    transform: translateX(-30px);
  }
}
